import { useApolloClient } from "@apollo/client/main.cjs";
import { Modal, Spinner } from "@shopify/polaris";
import { Box } from "@smartrr/shared/components/primitives";
import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import { useAsyncContentLoader } from "@smartrr/shared/hooks/useContentLoader";
import {
  SubscriptionContractDiscountFromQuery,
  SubscriptionContractFromQuery,
  SubscriptionLineItemFromQuery,
  getShopifySubscriptionContractDiscounts,
  queryShopifySubscriptionContract,
  queryShopifySubscriptionContractLineItems,
} from "@smartrr/shared/shopifyGraphQL/subscriptionContracts";
import { copyToClipboard } from "@smartrr/shared/utils/copyToClipboard";
import React, { useEffect } from "react";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { updateRawContractsModal } from "@vendor-app/app/_state/actionCreators/subscriptionDetails";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

interface IShowRawContractProps {
  customerPurchaseState: IPurchaseStateWithCustomerRelationship | undefined;
}

type ShopifyRawContract = {
  contract: SubscriptionContractFromQuery & { lines: SubscriptionLineItemFromQuery[] } & {
    discounts: SubscriptionContractDiscountFromQuery[];
  };
} | null;

export const ShowRawContract = ({ customerPurchaseState }: IShowRawContractProps) => {
  const rawContract = useAsyncContentLoader<ShopifyRawContract>(null);
  const { addToast } = useToast();
  const apolloClient = useApolloClient();
  const dispatch = useSmartrrVendorDispatch();

  const showContract = useSmartrrVendorSelector(state => state.subscriptionDetails.showRawContracts);
  const closeModal = () => dispatch(updateRawContractsModal(false));

  const viewRawContractData = async (shopifyId: string | undefined) => {
    if (!shopifyId) {
      return;
    }

    await rawContract.loadContent(async (_, setContract) => {
      const res = await queryShopifySubscriptionContract(shopifyId, apolloClient);
      if (res.type === "error") {
        addToast(`Couldn't get shopify data for contract ${shopifyId}`);
        return;
      }

      const subscriptionContractDiscountsPromise = getShopifySubscriptionContractDiscounts(
        shopifyId,
        apolloClient
      );

      const subscriptionLineItemsPromise = queryShopifySubscriptionContractLineItems(
        shopifyId,
        res.body.data.subscriptionContract!.linesCount?.count!,
        apolloClient
      );
      const [linesRes, discounts] = await Promise.all([
        subscriptionLineItemsPromise,
        subscriptionContractDiscountsPromise,
      ]);

      const lines =
        linesRes.type === "success"
          ? linesRes.body.data.subscriptionContract!.lines.edges.map(({ node }) => node)
          : [];

      setContract({
        contract: {
          ...res.body.data.subscriptionContract!,
          lines,
          discounts,
        },
      });
    });
  };

  useEffect(() => {
    if (showContract) {
      viewRawContractData(customerPurchaseState?.shopifyId);
    }
  }, [showContract, customerPurchaseState?.shopifyId]);

  return (
    <Modal
      title="Shopify data"
      open={showContract}
      onClose={() => {
        closeModal();
      }}
      primaryAction={{
        content: "Close",
        onAction() {
          closeModal();
        },
      }}
      secondaryActions={[
        {
          content: "Copy",
          onAction() {
            copyToClipboard(JSON.stringify(rawContract.content, null, 2), "Data copied to clipboard", addToast);
          },
        },
      ]}
    >
      <Box p={1}>
        {rawContract.isLoading ? (
          <Box justifyContent="center" alignItems="center" style={{ width: "100%" }}>
            <Spinner />
          </Box>
        ) : (
          <Box>
            <pre style={{ fontFamily: "monospace", margin: 0, whiteSpace: "pre-wrap" }}>
              {JSON.stringify(rawContract.content, null, 2)}
            </pre>
          </Box>
        )}
      </Box>
    </Modal>
  );
};
