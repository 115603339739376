import { Button, Layout, LegacyCard, Page, Spinner, Text } from "@shopify/polaris";
import { NO_OP_CALLBACK, adminRoutePrefix } from "@smartrr/shared/constants";
import { ISODateString } from "@smartrr/shared/entities/ISODateString";
import { OrgUtils } from "@smartrr/shared/entities/Organization";
import { RRuleManager } from "@smartrr/shared/entities/Schedule/RRuleManager";
import { ensureShopifyGid, shopifyGidToNumber } from "@smartrr/shared/utils/ensureShopifyGid";
import { isCPSPrepaid } from "@smartrr/shared/utils/isPrepaid";
import { toViewDate } from "@smartrr/shared/utils/renderViewDate";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import {
  cancelCustomerPurchaseState,
  cancelPrepaidCustomerPurchaseState,
  loadCustomerPurchaseStateByIdOrShopifyId,
  pauseCustomerPurchaseState,
} from "@vendor-app/app/_state/actionCreators/customerPurchaseState";
import {
  updateCancelSubscriptionModal,
  updateInvalidVariantModal,
  updateReplaceVariantModal,
  updateShowSetUnpauseDate,
} from "@vendor-app/app/_state/actionCreators/subscriptionDetails";
import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { uniqBy } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { navigateWithShopInQuery } from "@smartrr/vendor-portal/src/utils/navigateWithShopInQuery";

import { ShowCustomerInfo } from "./components/ShowCustomerInfo";
import { SubscriptionDetailsNavBar } from "./components/SubscriptionDetailsNavBar";
import { SubscriptionTabs } from "./components/SubscriptionTabs";
import { HandleReplaceVariantModal, InvalidVariantModal, SetUnpauseDateModal, ShowRawContract } from "./modals";
import { HandleCancelSubscription, SubscriptionCancellationType } from "./modals/HandleCancelSubscriptionModal";
import {
  getCPSStatusBadge,
  getFailedBillsAndCPSSubPropertiesErrors,
  useActualPurchaseStateStatus,
  useLineItemWithDeletedVariant,
  useSellingPlans,
} from "../libs";
import { BACK_TO_SUBSCRIPTIONS_BUTTON_TITLE } from "../../AdminCreateSubscriptionRouteNew/libs";
import { adjustToStoreBillingTime } from "@smartrr/shared/utils/dateUtils";
import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import { SubscriptionsTableAccess } from "../../AdminSubscriptionTableRoute/store";
import { SetupStoreAccess } from "../../AdminSetupRoute/libs/store";
import { CenterSpinner } from "../../components/elements/CenterSpinner";

const PageWrapper = styled.div`
  .Polaris-Page--narrowWidth {
    max-width: 62.5rem;
  }
`;

const SpinnerContainer = styled.div`
  align-items: center;
  display: flex;
  height: 90vh;
  justify-content: center;
`;

export const SubscriptionDetails = ({ subscriptionId }: { subscriptionId: string }) => {
  const dispatch = useSmartrrVendorDispatch();
  const isLoading = useSmartrrVendorSelector(state => state.purchaseStates.isLoading);
  const { sellingPlanGroupsLoading } = useSellingPlans();
  const tableCpsList = SubscriptionsTableAccess.useData();
  const reduxCpsList = useSmartrrVendorSelector(state => state.purchaseStates.data);
  const { initialize: initializeSetup } = SetupStoreAccess.useActions();

  const cpsId = useMemo(() => {
    return ensureShopifyGid("SubscriptionContract", subscriptionId);
  }, [subscriptionId]);

  const customerPurchaseState: IPurchaseStateWithCustomerRelationship | undefined = useMemo(() => {
    let cps: IPurchaseStateWithCustomerRelationship | undefined = reduxCpsList.find(
      cps => cps.shopifyId === cpsId
    );

    if (!cps) {
      cps = tableCpsList.find(cps => cps.shopifyId === cpsId);
    }

    return cps;
  }, [reduxCpsList, tableCpsList, cpsId]);

  useEffect(() => {
    if (!customerPurchaseState && subscriptionId) {
      dispatch(loadCustomerPurchaseStateByIdOrShopifyId(subscriptionId));
    }
  }, [subscriptionId]);

  useEffect(() => {
    initializeSetup(true);
  }, []);

  if (isLoading || sellingPlanGroupsLoading) {
    return (
      <SpinnerContainer id="subscription-details__is-loading-state">
        <Spinner />
      </SpinnerContainer>
    );
  }

  if (!customerPurchaseState) {
    return <LegacyCard sectioned>Subscription not found</LegacyCard>;
  }

  return <SubscriptionDetailsContent customerPurchaseState={customerPurchaseState} />;
};

export const SubscriptionDetailsContent = ({
  customerPurchaseState,
}: {
  customerPurchaseState: IPurchaseStateWithCustomerRelationship | undefined;
}) => {
  const dispatch = useSmartrrVendorDispatch();
  const organization = useActiveOrganizationSelector();
  const { addToast } = useToast();

  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
  const [failedBillingReasons, setFailedBillingReasons] = useState<{ error: string; id: string }[] | undefined>();
  const [shippingCalculationWarning, setShippingCalculationWarning] = useState<string | undefined>();

  const { actualPurchaseStateStatus } = useActualPurchaseStateStatus(customerPurchaseState);
  const vendorPortalPurchasablesLoading = useSmartrrVendorSelector(
    state => state.purchasables.isLoadingAdditionaly
  );
  const lineItemWithDeletedVariant = useLineItemWithDeletedVariant(customerPurchaseState);

  const { getSellingPlanGroup } = useSellingPlans();
  const sellingPlanGroup = getSellingPlanGroup(customerPurchaseState?.sellingPlanId);

  const isPrepaid = isCPSPrepaid(customerPurchaseState?.schedule);

  const rruleManager = useMemo(
    () =>
      customerPurchaseState?.schedule && organization
        ? RRuleManager.fromJson(customerPurchaseState.schedule, OrgUtils.getBillingSchedule(organization))
        : undefined,
    [customerPurchaseState?.schedule, organization]
  );

  const frequencyString = useMemo(
    () =>
      rruleManager
        ? `${isPrepaid ? "Prepaid" : ""} ${
            sellingPlanGroup?.name ? sellingPlanGroup.name + "," : ""
          } Delivers every ${rruleManager.getScheduleConfig().deliveryFrequencyValue} ${
            rruleManager.getScheduleConfig().frequencyUnit
          }`
        : "",
    [rruleManager, isPrepaid, sellingPlanGroup?.name]
  );

  const openSetUnpauseDateModal = () => dispatch(updateShowSetUnpauseDate(true));

  const onCancelSubscription = useCallback(
    async (type: SubscriptionCancellationType) => {
      if (!customerPurchaseState?.custRel) {
        return;
      }

      dispatch(updateCancelSubscriptionModal(false));
      setIsUpdatingStatus(true);

      if (type === "Regular" || type === "Prepaid") {
        await dispatch(
          cancelCustomerPurchaseState({
            customerPurchaseStateId: customerPurchaseState.id,
            customerRelationshipId: customerPurchaseState.custRel.id,
            addToast,
          })
        );
      } else if (type === "PrepaidAll") {
        await dispatch(
          cancelPrepaidCustomerPurchaseState({
            customerPurchaseStateId: customerPurchaseState.id,
            customerRelationshipId: customerPurchaseState.custRel.id,
            addToast,
          })
        );
      }

      setIsUpdatingStatus(false);
    },
    [customerPurchaseState, addToast]
  );

  const onPauseSubscription = useCallback(
    async (unpauseDate: Date) => {
      if (!customerPurchaseState?.custRel) {
        return;
      }

      const formattedUnpauseDate = unpauseDate
        ? adjustToStoreBillingTime(unpauseDate, organization?.billingTime, organization?.billingTimezone)
        : unpauseDate;

      await dispatch(
        pauseCustomerPurchaseState({
          customerPurchaseStateId: customerPurchaseState.id,
          customerRelationshipId: customerPurchaseState.custRel.id,
          unpauseDate: formattedUnpauseDate,
          addToast,
        })
      );
    },
    [customerPurchaseState, addToast]
  );

  useEffect(() => {
    getFailedBillsAndCPSSubPropertiesErrors(customerPurchaseState).then(failedCPSAndBills => {
      setFailedBillingReasons(uniqBy(failedCPSAndBills, "error"));
    });
  }, [customerPurchaseState]);

  useEffect(() => {
    if (customerPurchaseState?.subProperties?.shippingCalculationWarning) {
      setShippingCalculationWarning(customerPurchaseState.subProperties.shippingCalculationWarning.type);
      customerPurchaseState.totalShipping = 0;
    } else {
      setShippingCalculationWarning(undefined);
    }
  }, [customerPurchaseState, shippingCalculationWarning]);

  // Loading state used if there are multiple pages of purchasables
  if (vendorPortalPurchasablesLoading) {
    return <CenterSpinner />;
  }

  return (
    <PageWrapper id="subscription-details__page-wrapper">
      <Page
        narrowWidth
        title={`Subscription #${shopifyGidToNumber(customerPurchaseState?.shopifyId ?? "")}`}
        titleMetadata={customerPurchaseState ? getCPSStatusBadge(customerPurchaseState?.purchaseStateStatus) : ""}
        subtitle={frequencyString}
        additionalMetadata={
          customerPurchaseState?.unpauseDate ? (
            <Text variant="bodyMd" as="span" color="subdued">
              Set to auto-resume on{" "}
              {toViewDate(ISODateString.fromString(customerPurchaseState.unpauseDate).toJSDate(), {
                day: "numeric",
                month: "long",
                year: "numeric",
              })}
              {". "}
              <Button plain onClick={openSetUnpauseDateModal}>
                Change date?
              </Button>
            </Text>
          ) : null
        }
        primaryAction={
          <SubscriptionDetailsNavBar
            customerPurchaseState={customerPurchaseState}
            actualPurchaseStateStatus={actualPurchaseStateStatus}
            onCommitBegin={() => setIsUpdatingStatus(true)}
            onCommitEnd={() => setIsUpdatingStatus(false)}
            isUpdatingPurchaseState={isUpdatingStatus}
          />
        }
        backAction={{
          onAction: () => navigateWithShopInQuery(`${adminRoutePrefix}/subscriptions`),
          content: BACK_TO_SUBSCRIPTIONS_BUTTON_TITLE,
        }}
      >
        <Layout>
          <Layout.Section>
            {!!customerPurchaseState && (
              <SubscriptionTabs
                customerPurchaseState={customerPurchaseState}
                failedBillingReasons={failedBillingReasons}
                shippingCalculationWarning={shippingCalculationWarning}
              />
            )}
          </Layout.Section>
          <ShowCustomerInfo customerPurchaseState={customerPurchaseState} />
        </Layout>
        <React.Fragment>
          <HandleCancelSubscription
            onCancelSubscription={onCancelSubscription}
            onCloseModal={() => {
              dispatch(updateCancelSubscriptionModal(false));
              if (lineItemWithDeletedVariant) {
                dispatch(updateInvalidVariantModal(true));
              }
            }}
            isPrepaid={isPrepaid}
          />

          {lineItemWithDeletedVariant ? (
            <InvalidVariantModal
              lineItem={lineItemWithDeletedVariant}
              onClose={NO_OP_CALLBACK}
              onCancelSubscription={() => {
                dispatch(updateInvalidVariantModal(false));
                dispatch(updateCancelSubscriptionModal(true));
              }}
              onReplaceVariantClick={() => {
                dispatch(updateInvalidVariantModal(false));
                dispatch(updateReplaceVariantModal(true));
              }}
            />
          ) : null}

          <SetUnpauseDateModal
            initialUnpauseDate={customerPurchaseState?.unpauseDate}
            onConfirm={date => onPauseSubscription(date)}
          />
          <HandleReplaceVariantModal
            customerPurchaseState={customerPurchaseState}
            lineItemWithDeletedVariant={lineItemWithDeletedVariant}
            sellingPlanGroup={sellingPlanGroup}
          />

          <ShowRawContract customerPurchaseState={customerPurchaseState} />
        </React.Fragment>
      </Page>
    </PageWrapper>
  );
};
